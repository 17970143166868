import React, { Component } from 'react';
import PropTypes from 'prop-types';
import recipeLogoImg from '../../assets/logo.png';

import {
  Container,
  Logo,
  Title,
  UserContainer,
  UserTitle,
  UserName,
  OfficeContainer,
  OfficeTitle,
  UserOffice,
  DepartmentContainer,
  DepartmentTitle,
  UserDepartment,
  UserCompany,
  ValidationDate,
} from './styles';

export default class Recipe extends Component {
  render() {
    const { validation } = this.props;

    return (
      <Container>
        <Logo>
          <img src={recipeLogoImg} alt="RecipeImg" />
        </Logo>

        <Title>REFEIÇÃO AUTORIZADA PARA</Title>

        <UserContainer>
          <UserTitle>Nome:</UserTitle>
          <UserName>{validation && validation.name}</UserName>
        </UserContainer>

        <OfficeContainer>
          <OfficeTitle>Cargo:</OfficeTitle>
          <UserOffice>{validation && validation.office}</UserOffice>
        </OfficeContainer>

        <DepartmentContainer>
          <DepartmentTitle>Setor:</DepartmentTitle>
          <UserDepartment>
            {validation && validation.departments}
          </UserDepartment>
        </DepartmentContainer>

        <UserCompany>Empresa: {validation && validation.companies}</UserCompany>

        <ValidationDate>{validation && validation.created}</ValidationDate>
      </Container>
    );
  }
}

Recipe.propTypes = {
  validation: PropTypes.objectOf().isRequired,
};
